<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.kpTemplate"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        :label="$t('message.categories')"
                        class="label_mini"
                        prop="categories"
                      >
                        <select-categories
                          ref="Categories"
                          v-model="form.categories"
                          :category_ids="form.categories"
                          :size="'medium'"
                          :data="JSON.parse(JSON.stringify(this.categories)).filter(item => item.has_child === false)"
                          :placeholder="$t('message.categories')"
                        >
                        </select-categories>
                      </el-form-item>
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>

                      </el-form-item>
                      <el-form-item
                      prop="name"
                      :label="$t('message.header')"
                      >
                        <ckeditor :editor="editor" v-model="form.header" :config="editorConfig"></ckeditor>
                      </el-form-item>     

                      <el-form-item
                      prop="name"
                      :label="$t('message.footer')"
                      >
                        <ckeditor :editor="editor" v-model="form.footer" :config="editorConfig"></ckeditor>
                      </el-form-item>   

                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCategories from "@/components/multiSelects/multi-select-categories";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
  
  mixins: [form, drawer],
  data() {
    return {
      editor: Editor,
      editorConfig: {
          // The configuration of the editor.
      }
    };
  },
  components: { selectCategories },
  created() {},
  
  computed: {
      ...mapGetters({
          rules: "kpTemplates/rules",
          model: "kpTemplates/model",
          columns: "kpTemplates/columns",
          categories: "categories/inventory",
      }),
  },
  methods: {
      onReady( editor )  {
          // Insert the toolbar before the editable area.
          editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
          );
      },
      ...mapActions({
          save: "kpTemplates/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>

